<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/bienestarricotecnologia/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">Boomcredit, S.A. de C.V., SOFOM, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">HOME</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">ABOUT</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/introduce' }" class="Li3">
          <router-link to="/introduce" class="nav-link3">
            <span class="routeText3">CONTACT</span>
          </router-link>
        </li>
        <!-- <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="Li4"
        >
          <a href="https://pgg.actvospro.com/chi2La8su/j8po9st6emPaa.html" class="nav-link3" target="_blank">
            <span class="routeText4">PRIVACY</span>
          </a>
        </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->
          <span class="dropdown-toggle routeText4">PRIVACY</span>
          <ul class="dropdown-menu">
            <li class="firstLi">
              <a
                href="https://loa.boomcashloanapp.com/b0o3mc7s/cb30oC9ysxy.html?ownerShip=boomCash_ios"
                target="_blank"
              >
                <img
                  src="@/page/boomcredit/components/img/app.png"
                  alt="App Store"
                  class="iconImg"
                />
                App Store
              </a>
            </li>
            <li>
              <a
                href="https://loa.boomcashloanapp.com/b0o3mc7s/cb30oC9ysxy.html?ownerShip=boomCash"
                target="_blank"
              >
                <img
                  src="@/page/boomcredit/components/img/play.png"
                  alt="App Store"
                  class="iconImg"
                />
                Google Play</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height:90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #3C61DE;
  /* box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10); */
  /* box-shadow: 0px 2px 10px rgba(50, 55, 71, 0.10); */
  /* background-image: url("@/page/bienestarricotecnologia/components/img/NavBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #FFFFFF;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  height: 24px;
  line-height: 35px;
  /* height: 90px;
  line-height: 90px; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #FFFFFF;
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 4px;
  padding-right: 4px;
  padding: 6px;
  height: 39px;
  /* line-height: 90px; */
}
.Li1 {
  width: 120px;
}
.Li2 {
  width: 130px;
}
.Li3 {
  width: 150px;
}
.Li4 {
  width: 160px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.active-link {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  /* border-radius: 40px; */
  /* border-radius: 9px; */
  border-radius: 12px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  color: #3C61DE;
}
.title{
  /* border: 1px solid red; */
  /* padding-left: 50px; */
  width: 450px
}
.secondBox{
  padding: 0 200px;
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 10%;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  padding: 0px 0;
  min-width: 180px;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.dropdown-menu li:first-child:hover {
  border-radius: 12px 12px 0 0;
}
.dropdown-menu li:nth-child(2):hover {
  border-radius: 0 0 12px 12px;
}
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 15px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  padding-left: 20px;
  padding-top: 2px;
  display: flex;
  width: 100%;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
.firstLi {
  border-bottom: 1px solid #1D5CFB;
}
.iconImg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  /* padding-left: -20px; */
  /* margin: 0 10px 0 auto; */
}

</style>